







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '../../../types';
import CardRow from '@/components/CardRow.vue';

const AddMedicalInformationDialog = () => import('../dialogs/AddMedicalInformationDialog.vue');

@Component({
  components: { AddMedicalInformationDialog, CardRow }
})
export default class MedicalInformationCard extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
}
