






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_CONTACT_BY_ID, GET_HEALTH_INSURANCE_LIST } from '@/modules/contact/store';
import { ConservatorshipDetails } from '../../../types';
import CardRow from '@/components/CardRow.vue';
import { Contact } from '@/modules/contact/types';

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');
const AddHealthInsurancesMembershipDialog = () => import('../dialogs/AddHealthInsurancesMembershipDialog.vue');

const HealthInsurance = namespace('contact');

@Component({
  components: { AddHealthInsurancesMembershipDialog, CreateLetterButton, CardRow }
})
export default class HealthInsuranceCard extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @HealthInsurance.Getter(GET_CONTACT_BY_ID) getById!: (id: string) => Contact | undefined;
  @HealthInsurance.Getter(GET_HEALTH_INSURANCE_LIST) list!: Contact[];

  get healthInsurance(): Contact | undefined {
    if (!this.conservatorship.healthInsuranceMembership) return;

    return this.getById(this.conservatorship.healthInsuranceMembership.healthInsuranceId);
  }
}
