























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import HealthInsuranceCard from '../cards/HealthInsuranceCard.vue';
import MedicalInformationCard from '../cards/MedicalInformationCard.vue';

const DoctorPlugin = () => import('@/modules/conservatorshipToContact/components/DoctorPlugin.vue');

@Component({
  components: {
    DoctorPlugin,
    MedicalInformationCard,
    HealthInsuranceCard
  }
})
export default class MedicalDataTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
